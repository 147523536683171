<template>
    <div class="main-container container">
        <ul class="tabs">
            <li class="item cur">
                <span class="iconfont icon-zuixinnew3"></span>
                最新
            </li>
            <li class="item">
                <span class="iconfont icon-zuire"></span>
                最热
            </li>
            <li class="item">
                <span class="iconfont icon-fhic"></span>
                前端开发
            </li>
        </ul>
        <div class="main">
            <div class="mainL">
                <!-- 列表 -->
                <ul class="listbox">
                    <li class="item" v-for="(item,index) in [1,1,1,1,1,1,1,1,1,1,1]" :key="index">
                        <div class="top">
                            <div class="title">
                                <span class="totop">置顶</span>
                                <a href="">
                                    <h3>博客部署教程{{index+1}}</h3>
                                </a>
                            </div>
                            <p class="desc">
                                介绍了博客的部署教程，及安装和怎么部署的介绍了博客的部署教程，及安装和怎么部署的介绍了博客的部署教程，及安装和怎么部署的介绍了博客的部署教程，及安装和怎么部署的介绍了博客的部署教程，及安装和怎么部署的介绍了博客的部署教程，及安装和怎么部署的介绍了博客的部署教程，及安装和怎么部署的介绍了博客的部署教程，及安装和怎么部署的介绍了博客的部署教程，及安装和怎么部署的
                                介绍了博客的部署教程，及安装和怎么部署的
                            </p>
                        </div>
                        <div class="bottom">
                            <p class="pitem">
                                <span class="iconfont icon-yueduxiao"></span>阅读 1323
                            </p>
                            <p class="pitem">
                                <span class="iconfont icon-31pinglun"></span> 评论 23
                            </p>
                            <p class="pitem">
                                <span class="iconfont icon-dianzan"></span> 点赞 322
                            </p>
                            <p class="pitem">
                                <span class="iconfont icon-shoucang1"></span> 收藏 20
                            </p>
                            <p class="pitem">
                                <span class="iconfont icon-shijian"></span> 3月前
                            </p>
                        </div>
                    </li>
                </ul>
                <!-- 列表 end -->
            </div>
            <div class="mainR">
                <div class="recommed">
                    <div class="title">
                        <span class="iconfont"></span>
                        <h2>推荐文章</h2>
                    </div>
                    <ul class="recommedList">
                        <li class="item">
                            <div class="imgBox" :style="`background:url(${imgLogo}) center center no-repeat;background-size:cover`"></div>
                            <div class="rightCont">
                                <h3 class="pt">mysql多表查询使用排序值后执行速度变慢</h3>
                                <p class="time">2023-08-16 22:23:03</p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="imgBox" :style="`background:url(${imgLogo}) center center no-repeat;background-size:cover`"></div>
                            <div class="rightCont">
                                <h3 class="pt">mysql多表查询使用排序值后执行速度变慢mysql多表查询使用排序值后执行速度变慢</h3>
                                <p class="time">2023-08-16 22:23:03</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import imgLogo from "@/assets/logo.png"
export default {
    name: "App",
    data() {
        return {
            imgLogo
        };
    },
    components: {},
};
</script>

<style lang="scss" scoped>
</style>